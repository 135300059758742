import Request from "./request";

/*
 * In html page is obfuscated code
 */
/* global Request */

$("#loginForm").submit(function (event) {
    //https://stackoverflow.com/a/979997
    function gup(name, url) {
        if (!url)
            url = location.href;
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(url);
        return results == null ? null : results[1];
    }
    event.preventDefault();

    var $form = $(this),
            email = $form.find('input[name="email"]').val(),
            password = $form.find('input[name="password"]').val(),
            url = $form.attr('action');

    var credentials = {email: email, password: password};
    $("#error").empty();
    let body = JSON.stringify(credentials);
    let done = (data, textStatus, jqXHR) => {
        if (jqXHR.status === 200) {
            var parentHref = gup("parent", window.location.href);
            parentHref !== null ? window.location.replace(parentHref) : window.location.replace("/");
        }
    };
    let fail = (jqXHR) => {
        $("#error").append(jqXHR.responseText);
    };

    Request.login(body, done, fail);
});
$(document).ready(function () {
    let done = function (data) {
        $("#licOrg").text(data.licOrg);
        $("#licID").text(data.licId);
        $("#orgName").text(data.title);
        $("title").text(data.title);
        if (data.validUntil * 1000 < Date.now()) {
            $("#error").append("License has expired.");
            $("input").attr("disabled", "true");
            $("button").attr("disabled", "true");
        }
    };

    Request.getAuthenticationInfo(done);
});

